
/* tslint:disable max-line-length | no-trailing-whitespace */
import * as React from 'react';
import { Types } from '@shapeable/core';

import LOGO from '../images/app-logo-inverted.png';

export const ApplicationLogoInverted: React.FC<Types.Classable> = ({ className }) => (
  <img className={className} src={LOGO} />
);


